import { CircularProgress } from "@mui/material";
import React from "react";
import { FiEdit3 } from "react-icons/fi";

const AssignBtn = ({ label, showProgress }) => {
  return (
<button className="bg-[#3EB4E9] border text-xs p-2 rounded-full flex items-center gap-1 hover:bg-[#60C8F9]">
      {showProgress === label ? (
        <div
          className={`${
            showProgress === "Assign All" ? "px-[33px]" : "px-[49px]"
          } `}
        >
          <CircularProgress color="default" size={11} />
        </div>
      ) : (
        <>
          <FiEdit3 color="#fff" />
          <div className="text-white hover:text-[#000] font-semibold">{label}</div>
        </>
      )}
    </button>
  );
};

export default AssignBtn;
