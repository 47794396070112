import React, { useContext, useEffect, useState } from "react";
import LocationPicker from "../components/LocationPicker";
import Back from "../components/Back";
import { DistrictsContext } from "../context/DistrictsContext";
import { useParams } from "react-router-dom";
import SelectedDistrictTable from "../components/SelectedDistricTable";
import WellHistoryListCard from "../components/WellHistoryListCard";
import WellDetailsCard from "../components/WellDetailsCard"; // Import WellDetailsCard
import { FaCaretDown } from "react-icons/fa";
import download_1 from "../assets/download_1.png";
import SurveyDetailsCard from "../components/SurveyDetailsCard";
import WellHistoryDetailsCard from "../components/WellHistoryDetailsCard";
import axiosInstance from "../interceptor/axiosInstance";
import * as XLSX from "xlsx";
import { CircularProgress } from "@mui/material";

const chooseYear = [
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];

const chooseMonth = [
  {
    start: "Jan",
    end: "Mar",
  },
  {
    start: "Apr",
    end: "Jun",
  },
  {
    start: "Jul",
    end: "Sep",
  },
  {
    start: "Oct",
    end: "Dec",
  },
];
const SelectedWellHistory = () => {
  const [dateDropDown, setDateDropDown] = useState();
  const [wellHistoryData, setwellHistoryData] = useState([]);
  const [originalHistoryData, setOriginalHistData] = useState([]);
  const [selectedWellDetails, setSelectedWellDetails] = useState(null); // State to hold selected well details
  const [selectedFilter, setSelectedFilter] = useState({
    year: null,
    start: null,
    end: null,
  });
  const [showProgress, setShowProgress] = useState();

  const { districts } = useContext(DistrictsContext);
  const { district_id, exWellId, wellId } = useParams();

  const handleChooseMonth = (start, end) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  };

  const handleChooseYear = (year) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      year: year,
    }));
  };

  const handleSearch = () => {
    const from = new Date(
      `01-${selectedFilter?.start}-${selectedFilter?.year}`
    );
    const till = new Date(`31-${selectedFilter?.end}-${selectedFilter?.year}`);

    const filterData = originalHistoryData?.filter((item) => {
      const itemDate = new Date(item?.date);
      return itemDate >= from && itemDate <= till;
    });

    setwellHistoryData(filterData);
  };

  const selectedDistrict = districts?.find(
    (item) => item.district_id == district_id
  );

  const fetchHistoryData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/district/well/history/${wellId}`
      );

      const historyFinal = response?.data;
      setwellHistoryData(historyFinal?.Data);
      setOriginalHistData(historyFinal?.Data);
    } catch (error) {
      console.error("Error fetching well history data", error);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    fetchHistoryData();
  }, [wellId]);
  const handleShowDetails = (item) => {
    setSelectedWellDetails(item); // Set the selected well details to display
  };

  const handleDownloadExcel = () => {
    // Format data based on well_type
    const formattedData = wellHistoryData?.map((well, i) => {
      if (well?.well_type.toLowerCase().includes("dug well")) {
        return {
          "No." : i+1,
          District: selectedDistrict?.district_name,
          "Well ID": well?.ex_well_id || "-",
          "Well Type": well?.well_type || "-",
          Village: well?.village || "-",
          "Survey Date": well?.createdat || "-",
          "Survey By": well?.name || "-",
          "Depth(m)": well?.survey_depth || "-",
          "Dia(m)": well?.survey_diameter || "-",
          MP: well?.survey_measuring_point || "-",
          EC: well?.electrical_conductivity || "-",
          Temp: well?.temperature || "-",
          "Water level": well?.water_level || "-",
          PH: well?.ph_value || "-",
          TH: well?.th_value || "-",
          CA: well?.ca_value || "-",
          MG: well?.mg_value || "-",
          NA: well?.na_value || "-",
          K: well?.k_value || "-",
          CO3: well?.co3_value || "-",
          HCO3: well?.hco3_value || "-",
          CL: well?.cl_value || "-",
          SO4: well?.so4_value || "-",
          NO3: well?.no3_value || "-",
          F: well?.f_value || "-",
          U: well?.u_value || "-",
          Remarks: well?.remarks || "-",
        };
      } else if (well?.well_type.includes("Piezometer")) {
        return {
          "No." : i+1,
          District: selectedDistrict?.district_name,
          "Well ID": well?.ex_well_id || "-",
          "Well Type": well?.well_type || "-",
          Village: well?.village || "-",
          "Survey Date": well?.createdat || "-",
          "Survey By": well?.name || "-",
          "Protection Box Status": well?.protection_box_status || "-",
          "Type of lock": well?.type_of_lock || "-",
          "Water level": well?.water_level || "-",
          EC: well?.electrical_conductivity || "-",
          Temp: well?.temperature || "-",
          PH: well?.ph_value || "-",
          TH: well?.th_value || "-",
          CA: well?.ca_value || "-",
          MG: well?.mg_value || "-",
          NA: well?.na_value || "-",
          K: well?.k_value || "-",
          CO3: well?.co3_value || "-",
          HCO3: well?.hco3_value || "-",
          CL: well?.cl_value || "-",
          SO4: well?.so4_value || "-",
          NO3: well?.no3_value || "-",
          F: well?.f_value || "-",
          U: well?.u_value || "-",
          Remarks: well?.remarks || "-",
        };
      }
      return {};
    });
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${selectedDistrict?.district_name.toLowerCase()}-${exWellId.toLowerCase()}-well-history.xlsx`
    );
  };

  return (
    <>
      <div className="w-[90%] mx-auto pb-4 bg-softGrey">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              <span className="">
                <Back />
              </span>{" "}
              Selected Well({exWellId}) - History
            </h2>
            <LocationPicker />
          </div>
        </div>
        <div className="flex items-start gap-5">
          <div className="w-[55%]">
            <SelectedDistrictTable selectedDistrict={selectedDistrict} />
            <div className="relative">
              <div className="flex items-center mt-4 gap-10">
                <div className="bg-white flex items-center gap-4 rounded-lg font-roboto text-sm px-4 py-2">
                  <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                    <p
                      onClick={() =>
                        setDateDropDown(dateDropDown === "month" ? "" : "month")
                      }
                      className="flex items-center justify-between cursor-pointer"
                    >
                      {selectedFilter?.start && selectedFilter?.end
                        ? selectedFilter.start + "-" + selectedFilter?.end
                        : "Choose Month"}
                      <span>
                        <FaCaretDown />
                      </span>
                    </p>
                    <div
                      className={`${
                        dateDropDown === "month"
                          ? "inline-block z-10 top-10"
                          : "-top-10 -z-50"
                      } absolute bg-white left-0 rounded-lg w-[100%] shadow-xl transition-all duration-300`}
                    >
                      <ul>
                        {chooseMonth?.map((item, i) => (
                          <li
                            onClick={() =>
                              handleChooseMonth(item?.start, item?.end)
                            }
                            key={i}
                            className={`${
                              i === 0
                                ? "rounded-t-lg"
                                : i === chooseMonth.length - 1
                                ? "rounded-b-lg"
                                : ""
                            } ${
                              selectedFilter?.start === item?.start &&
                              selectedFilter?.end === item?.end
                                ? "bg-buttonPrimary text-white"
                                : ""
                            } p-1 cursor-pointer text-center hover:bg-buttonPrimary hover:text-white `}
                          >
                            {item?.start}-{item?.end}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                    <p
                      onClick={() =>
                        setDateDropDown(dateDropDown === "year" ? "" : "year")
                      }
                      className="flex items-center justify-between cursor-pointer"
                    >
                      {selectedFilter?.year
                        ? selectedFilter?.year
                        : "Choose Year"}
                      <span>
                        <FaCaretDown />
                      </span>
                    </p>
                    <div
                      className={`${
                        dateDropDown === "year"
                          ? "inline-block z-10 top-10"
                          : "-top-10 -z-50"
                      } absolute bg-white rounded-lg left-0 w-[130%] shadow-xl transition-all duration-300`}
                    >
                      <ul className="grid grid-cols-2">
                        {chooseYear?.map((item, i) => (
                          <li
                            onClick={() => handleChooseYear(item)}
                            key={i}
                            className={`${
                              i === 0
                                ? "rounded-tl-lg"
                                : i === 1
                                ? "rounded-tr-lg"
                                : i === chooseYear.length - 1
                                ? "rounded-br-lg"
                                : i === chooseYear.length - 2
                                ? "rounded-bl-lg"
                                : ""
                            } ${
                              selectedFilter?.year === item
                                ? "bg-buttonPrimary text-white"
                                : ""
                            } p-1 text-center cursor-pointer hover:bg-buttonPrimary hover:text-white`}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <button
                    onClick={handleSearch}
                    title={
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                        ? "Please select both month and year to enable search"
                        : ""
                    }
                    disabled={
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                    }
                    className={`${
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                        ? "bg-lightGrey text-black"
                        : "bg-buttonPrimary text-white"
                    } text-sm transition-colors duration-300 rounded-lg px-3 py-1`}
                  >
                    Search
                  </button>
                </div>
                <div onClick={handleDownloadExcel} className="cursor-pointer">
                  <h1 className="flex text-[#F7004B] items-center gap-1 font-bold text-sm">
                    Download as Excel{" "}
                    <img className="w-4 h-4" src={download_1} alt="" />
                  </h1>
                </div>
              </div>
            </div>

            <div className="my-4 w-[100%] flex flex-col gap-5 h-[50vh] overflow-y-auto custom-scrollbar">
              {showProgress ? (
                <div className="w-full mx-auto h-[30vh] flex items-center justify-center">
                  <CircularProgress size={24} />
                </div>
              ) : (
                <>
                  <p className="font-poppins font-medium">
                    {wellHistoryData?.length} well{" "}
                    {wellHistoryData?.length > 1 ? "histories" : "history"}
                  </p>
                  {wellHistoryData.map((item, i) => (
                    <WellHistoryListCard
                      cardNumber={i + 1}
                      selectedWellDetails={selectedWellDetails}
                      key={item.id}
                      item={item}
                      setwellHistoryData={setwellHistoryData}
                      handleShowDetails={handleShowDetails}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="w-[45%] bg-white rounded-lg">
            {selectedWellDetails ? (
              <WellHistoryDetailsCard
                wellId={exWellId}
                well={selectedWellDetails}
              />
            ) : (
              <div className="font-poppins font-semibold h-[70vh] flex items-center justify-center text-buttonSecondary">
                Select a survey to view details
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedWellHistory;
