import React, { useContext, useRef, useState } from "react";
import * as xlsx from "xlsx"; // Make sure to install the xlsx library
import cloudComputing from "../assets/upload_btn.png";
import axiosInstance from "../interceptor/axiosInstance";
import { DistrictsContext } from "../context/DistrictsContext";

const toSnakeCase = (str) => {
  return str
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscore between lower and uppercase letters
    .toLowerCase(); // Convert to lowercase
};

function UploadPiezometerSheet({ setOpenModal }) {
  const [msg, setMsg] = useState("");
  const { setUpdatedModal } = useContext(DistrictsContext);

  const requiredKeys = [
    "district",
    "ex_well_id",
    "village",
    "location_detail",
    "well_type",
    "well_status",
    "latitude",
    "longitude",
    "diameter",
    "mp",
    "depth",
    "key_no",
    "vendor",
  ];

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const readUploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      if (
        !file.type.includes("excel") &&
        !file.name.endsWith(".xls") &&
        !file.name.endsWith(".xlsx")
      ) {
        alert("Please upload a valid Excel file (.xls or .xlsx)");
        return;
      }
      setMsg("Your file is being processed...");
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result); // e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { header: 1 }); // Get rows as arrays
        const headers = json[0].map((header) => toSnakeCase(header)); // First row as headers

        // Check for missing required keys
        const missingKeys = requiredKeys.filter(
          (key) => !headers.includes(key)
        );
        if (missingKeys.length > 0) {
          setMsg(
            `The following required data are missing: ${missingKeys.join(", ")}`
          );
          return; // Prevent further processing
        }

        // Check for unwanted keys
        const unwantedKeys = headers.filter(
          (header) => !requiredKeys.includes(header)
        );
        if (unwantedKeys.length > 0) {
          setMsg(
            `The following fields are unwanted and should be removed: ${unwantedKeys.join(
              ", "
            )}`
          );
          return; // Prevent further processing
        }
        const result = json.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index] === undefined ? null : row[index]; // Set to null if value is empty
          });
          return obj;
        });

        const postData = {
          Data: result,
        };

        try {
          setMsg("Your file is being uploaded...");
          const response = await axiosInstance.post(
            "/api/well/creation",
            postData
          );

          if (!response.ok) {
            const errorData = response;
            if (response.status === 400) {
              setMsg(`Bad Request: ${errorData.message}`);
            } else if (response.status === 500) {
              setMsg("Server error, please try again later.");
            }
            return;
          }
          const data = response?.data;
        } catch (error) {
          console.error("Error:", error);
          setMsg("Network error, please try again later.");
        } finally {
          setMsg("Worksheet uploaded successfully!");
          setOpenModal("");
          setUpdatedModal(true);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  // 4000/api/well/creation emp_id

  return (
    <div className="">
      <div onClick={handleUploadClick} className="flex justify-center">
        <button className="px-6 text-black flex items-center  text-md py-2 rounded-lg font-semibold transition bg-[#009DE3] duration-300 ease-in-out transform ">
          <img src={cloudComputing} alt="" className="w-6 mr-2" />
          Choose File
        </button>
      </div>

      <input
        ref={fileInputRef}
        className="text-blackSubDark font-roboto text-sm hidden"
        type="file"
        name="upload"
        id="upload"
        accept=".xls,.xlsx"
        onChange={readUploadFile}
      />
      {msg ? (
        <main>
          <p
            className={`font-roboto text-center p-3 ${
              msg.includes("successfully") ||
              msg.includes("being uploaded") ||
              msg.includes("being processed")
                ? "text-successGreen"
                : "text-dullRed"
            } font-semibold `}
          >
            {msg}
          </p>
        </main>
      ) : null}
    </div>
  );
}

export default UploadPiezometerSheet;
