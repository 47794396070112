import React, { useContext, useState, useEffect } from "react";
import { GiCancel } from "react-icons/gi";
import { DistrictsContext } from "../context/DistrictsContext";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress, IconButton } from "@mui/material";
const EditFormModal = () => {
  const { editFormModal, setEditFormModal, setUpdatedModal } =
    useContext(DistrictsContext);

  const [circularProgress, setCircularProgress] = useState();
  const [updatedDetails, setUpdatedDetails] = useState(
    editFormModal?.well_type.toLowerCase().includes("dug well")
      ? {
          well_type: 0,
          well_id: editFormModal?.well_id,
          ex_well_id: editFormModal?.ex_well_id,
          village: editFormModal?.village,
          location_detail: editFormModal?.location_detail,
          latitude: editFormModal?.latitude,
          longitude: editFormModal?.longitude,
        }
      : {
          well_type: 1,
          well_id: editFormModal?.well_id,
          ex_well_id: editFormModal?.ex_well_id,
          village: editFormModal?.village,
          location_detail: editFormModal?.location_detail,
          latitude: editFormModal?.latitude,
          longitude: editFormModal?.longitude,
          depth: editFormModal?.depth,
          diameter: editFormModal?.diameter,
          mp: editFormModal?.measuring_point,
          key_no: editFormModal?.key_no,
        }
  );

  const dugWellFormItems = [
    { label: "Well Id", type: "text", value: editFormModal?.ex_well_id },
    { label: "Village", type: "text", value: editFormModal?.village },
    {
      label: "Location Detail",
      type: "text",
      value: editFormModal?.location_detail,
    },
    { label: "Latitude", type: "text", value: editFormModal?.latitude },
    { label: "Longitude", type: "text", value: editFormModal?.longitude },
  ];

  const piezometerFormItems = [
    { label: "Well Id", type: "text", value: editFormModal?.ex_well_id },
    { label: "Village", type: "text", value: editFormModal?.village },
    {
      label: "Location Detail",
      type: "text",
      value: editFormModal?.location_detail,
    },
    {
      type: "flex",
      items: [
        { label: "Latitude", type: "text", value: editFormModal?.latitude },
        { label: "Longitude", type: "text", value: editFormModal?.longitude },
      ],
    },
    {
      type: "flex",
      items: [
        { label: "Depth", type: "text", value: editFormModal?.depth },
        { label: "Diameter", type: "number", value: editFormModal?.diameter },
      ],
    },
    {
      type: "flex",
      items: [
        {
          label: "Measuring Point",
          type: "text",
          value: editFormModal?.measuring_point || "",
        },
        { label: "Key No", type: "text", value: editFormModal?.key_no },
      ],
    },
    { label: "Vendor", type: "text", value: editFormModal?.vendor },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormModal((prev) => ({
      ...prev,
      [name === "well_id" ? "ex_well_id" : name]: value,
    }));
    setUpdatedDetails((prev) => ({
      ...prev,
      [name === "well_id"
        ? "ex_well_id" // Map "well_id" input field to "ex_well_id" key
        : name === "measuring_point"
        ? "mp" // Handle mapping for "measuring_point"
        : name]: value,
    }));
  };

  const handleWellStatusUpdate = async () => {
    setCircularProgress("status update");

    try {
      const { well_id, status } = editFormModal;

      if (!well_id || !status) {
        throw new Error("Missing well_id or status");
      }

      const newStatus = status === "Active" ? "Closed" : "Active";
      const updateStatus = await axiosInstance.put("/api/well/status/update", {
        well_id: well_id,
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating well status:", error);
    } finally {
      setCircularProgress(null);
      setEditFormModal();
      setUpdatedModal(true);
    }
  };

  const handleUpdate = async () => {
    setCircularProgress("update");

    try {
      if (!updatedDetails) {
        throw new Error("Missing details");
      }
      const response = await axiosInstance.put(
        "/api/well/update/survey/detail",
        updatedDetails
      );
    } catch (error) {
      console.error("Error updating well details:", error);
    } finally {
      setCircularProgress(null);
      setEditFormModal();
      setUpdatedModal(true);
    }
  };

  useEffect(() => {
    if (editFormModal) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [editFormModal]);

  return (
    <div
      className="fixed z-20 inset-0 element {
        box-shadow: rgba(0, 0, 0, 0.48) 6px 2px 16px 0px, rgba(0, 0, 0, 0.8) -6px -2px 16px 0px;
        }
        bg-black bg-opacity-50 flex items-center justify-center font-roboto"
    >
      <div className="bg-white w-[30%] relative h-[90vh] overflow-y-auto hide-scrollbar rounded-[16px] px-10 py-5 text-[13px]">
        <div className="flex items-center  mt-5 justify-center text-base font-bold text-buttonSecondary font-poppins">
          <h2 className="text-center">
            Edit Well ({editFormModal?.well_type}) Details
          </h2>

          <div
            onClick={() => setEditFormModal()}
            className="cursor-pointer hover:bg-black hover:bg-opacity-10 rounded-full p-2 absolute top-3 right-3"
          >
            <GiCancel className="text-xl" />
          </div>
        </div>
        <button
          onClick={handleWellStatusUpdate}
          className="px-6 py-2 my-4 rounded-lg bg-softGrey border-[0.7px] border-[#009DE3] w-fit text-left font-bold text-errorRed"
        >
          {circularProgress === "status update" ? (
            <CircularProgress color="error" size={17} />
          ) : editFormModal?.status === "Active" ? (
            "Deactivate"
          ) : (
            "Activate"
          )}
        </button>

        <div className="flex flex-col gap-1">
          {editFormModal?.well_type.toLowerCase().includes("dug well")
            ? dugWellFormItems.map((item, index) => (
                <div key={index}>
                  <p className="text-[11px] opacity-70 pt-2 ">{item.label}</p>
                  <input
                    onChange={handleInputChange}
                    //                 .replace(/\s+/g, "_") // Replace spaces with underscores
                    // .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscore between lower and uppercase letters
                    // .toLowerCase(); // Convert to lowercase
                    name={item?.label.toLowerCase().replace(/\s+/g, "_")}
                    value={item?.value || ""}
                    type={item?.type}
                    className={`focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full`}
                  />
                </div>
              ))
            : piezometerFormItems.map((item, index) =>
                item?.type === "flex" ? (
                  <div key={index} className="flex items-center gap-4">
                    {item?.items?.map((item, index) => (
                      <div key={index}>
                        <p className="text-[11px] opacity-70 pt-2 ">
                          {item?.label}
                        </p>
                        <input
                          onChange={handleInputChange}
                          name={item?.label.toLowerCase().replace(/\s+/g, "_")}
                          value={item?.value || ""}
                          type={item?.type}
                          className="focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div key={index}>
                    <p className="text-[11px] opacity-70 pt-2 ">{item.label}</p>
                    <input
                      onChange={handleInputChange}
                      name={item?.label.toLowerCase().replace(/\s+/g, "_")}
                      value={item?.value || ""}
                      type={item?.type}
                      className="focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full"
                    />
                  </div>
                )
              )}
        </div>
        <div className="flex justify-end items-center">
          <button
            onClick={handleUpdate}
            className="my-5 bg-buttonPrimary text-white py-2 px-10 rounded-lg font-bold font-poppins text-xs w-fit"
          >
            {circularProgress === "update" ? (
              <CircularProgress color="default" size={17} />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFormModal;
