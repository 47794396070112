import React, { useEffect, useState } from "react";
import LocationPicker from "../components/LocationPicker";
import Table from "../components/Table";
import { FaCaretDown } from "react-icons/fa6";
import axiosInstance from "../interceptor/axiosInstance";

const chooseYear = [
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];

const chooseMonth = [
  {
    start: "Jan",
    end: "Mar",
  },
  {
    start: "Apr",
    end: "Jun",
  },
  {
    start: "Jul",
    end: "Sep",
  },
  {
    start: "Oct",
    end: "Dec",
  },
];
const ReportsAndAnalytics = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [dateDropDown, setDateDropDown] = useState();
  const [selectedFilter, setSelectedFilter] = useState({
    year: null,
    start: null,
    end: null,
  });
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
    startMonth: null,
    endMonth: null,
    year: null,
  });

  const handleChooseMonth = (start, end) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  };

  const handleChooseYear = (year) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      year: year,
    }));
  };

  const handleSearch = () => {
    const from = new Date(`${selectedFilter?.start}-${selectedFilter?.year}`);
    const till = new Date(`${selectedFilter?.end}-${selectedFilter?.year}`);
    const year = from.getFullYear();
    const startMonth = from.getMonth();
    const startMonthAbbr = from.toLocaleString("en-US", {
      month: "short",
    });
    const startDate = `${year}-${String(startMonth + 1).padStart(2, "0")}`;
    const endMonth = till.getMonth();
    const endMonthAbbr = till.toLocaleString("en-US", { month: "short" });
    const endDate = `${year}-${String(endMonth + 1).padStart(2, "0")}`;
    setFilterDate((prev) => ({
      ...prev,
      startMonth: startMonthAbbr,
      endMonth: endMonthAbbr,
      year: year,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth(); // Get the current month (0-11)
    const currMonthAbbr = date.toLocaleString("en-US", { month: "short" }); // Abbreviated current month

    let startMonthAbbr, endMonthAbbr, startDate, endDate;

    // Determine the quarter based on the current month
    if (month >= 0 && month <= 2) {
      startMonthAbbr = "Jan";
      endMonthAbbr = "Mar";
      startDate = `${year}-01-01`;
      endDate = `${year}-03-31`;
    } else if (month >= 3 && month <= 5) {
      startMonthAbbr = "Apr";
      endMonthAbbr = "Jun";
      startDate = `${year}-04-01`;
      endDate = `${year}-06-30`;
    } else if (month >= 6 && month <= 8) {
      startMonthAbbr = "Jul";
      endMonthAbbr = "Sep";
      startDate = `${year}-07-01`;
      endDate = `${year}-09-30`;
    } else if (month >= 9 && month <= 11) {
      startMonthAbbr = "Oct";
      endMonthAbbr = "Dec";
      startDate = `${year}-10-01`;
      endDate = `${year}-12-31`;
    }

    // Update the filterDate state with the calculated quarter
    setFilterDate((prev) => ({
      ...prev,
      startMonth: startMonthAbbr,
      endMonth: endMonthAbbr,
      year: year,
      startDate: startDate,
      endDate: endDate,
    }));

    console.log("Initial quarter date filter:", {
      startMonthAbbr,
      endMonthAbbr,
      startDate,
      endDate,
    });
  }, []);

  const analyticsData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `api/district/analysis/data/${filterDate?.startDate}/${filterDate?.endDate}`
      );

      const result = response?.data?.data;
      setreportData(result || []);
    } catch (error) {
      console.error("error", error);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    if (filterDate?.startDate && filterDate?.endDate) {
      analyticsData();
    }
  }, [filterDate]);

  return (
    <>
      <div className="w-[90%] mx-auto bg-softGrey">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              Report and Analytics
            </h2>

            <LocationPicker />
          </div>
        </div>
        <div className="relative">
          <div className="flex items-center mt-4 gap-10">
            <div className="bg-white flex items-center gap-4 rounded-lg font-roboto text-sm px-4 py-2">
              <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                <p
                  onClick={() =>
                    setDateDropDown(dateDropDown === "month" ? "" : "month")
                  }
                  className="flex items-center justify-between cursor-pointer"
                >
                  {selectedFilter?.start && selectedFilter?.end
                    ? selectedFilter.start + "-" + selectedFilter?.end
                    : "Choose Month"}
                  <span>
                    <FaCaretDown />
                  </span>
                </p>
                <div
                  className={`${
                    dateDropDown === "month"
                      ? "inline-block z-10 top-10"
                      : "-top-10 -z-50"
                  } absolute bg-white left-0 rounded-lg w-[100%] shadow-xl transition-all duration-300`}
                >
                  <ul>
                    {chooseMonth?.map((item, i) => (
                      <li
                        onClick={() =>
                          handleChooseMonth(item?.start, item?.end)
                        }
                        key={i}
                        className={`${
                          i === 0
                            ? "rounded-t-lg"
                            : i === chooseMonth.length - 1
                            ? "rounded-b-lg"
                            : ""
                        } ${
                          selectedFilter?.start === item?.start &&
                          selectedFilter?.end === item?.end
                            ? "bg-buttonPrimary text-white"
                            : ""
                        } p-1 cursor-pointer text-center hover:bg-buttonPrimary hover:text-white `}
                      >
                        {item?.start}-{item?.end}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                <p
                  onClick={() =>
                    setDateDropDown(dateDropDown === "year" ? "" : "year")
                  }
                  className="flex items-center justify-between cursor-pointer"
                >
                  {selectedFilter?.year ? selectedFilter?.year : "Choose Year"}
                  <span>
                    <FaCaretDown />
                  </span>
                </p>
                <div
                  className={`${
                    dateDropDown === "year"
                      ? "inline-block z-10 top-10"
                      : "-top-10 -z-50"
                  } absolute bg-white rounded-lg left-0 w-[130%] shadow-xl transition-all duration-300`}
                >
                  <ul className="grid grid-cols-2">
                    {chooseYear?.map((item, i) => (
                      <li
                        onClick={() => handleChooseYear(item)}
                        key={i}
                        className={`${
                          i === 0
                            ? "rounded-tl-lg"
                            : i === 1
                            ? "rounded-tr-lg"
                            : i === chooseYear.length - 1
                            ? "rounded-br-lg"
                            : i === chooseYear.length - 2
                            ? "rounded-bl-lg"
                            : ""
                        } ${
                          selectedFilter?.year === item
                            ? "bg-buttonPrimary text-white"
                            : ""
                        } p-1 text-center cursor-pointer hover:bg-buttonPrimary hover:text-white`}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <button
                onClick={handleSearch}
                title={
                  selectedFilter?.year === null ||
                  undefined ||
                  selectedFilter?.start === null ||
                  undefined ||
                  selectedFilter?.end === null ||
                  undefined
                    ? "Please select both month and year to enable search"
                    : ""
                }
                disabled={
                  selectedFilter?.year === null ||
                  undefined ||
                  selectedFilter?.start === null ||
                  undefined ||
                  selectedFilter?.end === null ||
                  undefined
                }
                className={`${
                  selectedFilter?.year === null ||
                  undefined ||
                  selectedFilter?.start === null ||
                  undefined ||
                  selectedFilter?.end === null ||
                  undefined
                    ? "bg-lightGrey text-black"
                    : "bg-buttonPrimary text-white"
                } text-sm transition-colors duration-300 rounded-lg px-3 py-1`}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div>
          <Table
            showProgress={showProgress}
            filterDate={filterDate}
            reportData={reportData}
          />
        </div>
      </div>
    </>
  );
};

export default ReportsAndAnalytics;
