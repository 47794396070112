import React, { useContext, useEffect, useState } from "react";
import logoImg from "../assets/small_logo.png";
import profilepic from "../assets/profilepic.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DistrictsContext } from "../context/DistrictsContext";
import { useDispatch } from "react-redux";
import { clearAssignment } from "../redux/assignmentSlice";
import store from "../redux/store";
import { clearAuth } from "../redux/authSlice";

const navItems = [
  {
    name: "District Management",
    path: "/district-management",
  },
  {
    name: "Employee Management",
    path: "/employee-management",
  },
  {
    name: "Survey Assignments",
    path: "/employee-assignments",
  },
  {
    name: "Survey Reports & Analytics",
    path: "/reports-and-analytics",
  },
  // {
  //   name: "Survey Review",
  //   path: "/survey-review",
  // },
  // {
  //   name: "Upload Files",
  //   path: "/upload-files",
  // },
  // {
  //   name: "Notifications & Alerts",
  //   path: "/notifications-and-alerts",
  // },
];

const Navbar = () => {
  const { currTab, setCurrTab } = useContext(DistrictsContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleTab = (tab) => {
    tab === "Employee Assignments" && dispatch(clearAssignment());
  };
  const { auth } = store.getState();

  const userName = auth.user?.Name || "User"; // Default to "User" if no name
  const firstLetter = userName.charAt(0).toUpperCase(); // First letter of the user's name
  

  const handleLogout = () => {
    dispatch(clearAuth());
    navigate("/");
  };

  useEffect(() => {
    if (
      location.pathname.includes("district-management") ||
      location.pathname.includes("selected-district")
    ) {
      setCurrTab("District Management");
    } else if (location.pathname.includes("employee-management")) {
      setCurrTab("Employee Management");
    } else if (
      location.pathname.includes("employee-assignments") ||
      location.pathname.includes("assign-work")
    ) {
      setCurrTab("Survey Assignments");
    } else if (
      location.pathname.includes("reports-and-analytics") ||
      location.pathname.includes("survey-review") ||
      location.pathname.includes("missed-survey")
    ) {
      setCurrTab("Survey Reports & Analytics");
    }
    // Add more conditions as necessary for other tabs/pages
  }, [location.pathname]);

  return (
    <>
      {logoutModal ? (
        <div className="fixed inset-0 z-20  flex items-center justify-center bg-black bg-opacity-50 font-roboto">
          <div className="bg-white w-[30%] h-[30vh] rounded-xl flex flex-col gap-5 items-center justify-center">
            <h2 className="text-blackSubDark text-lg font-medium">
              Logout Confirmation
            </h2>
            <p>Do you want to logout?</p>
            <div className="flex items-center justify-center gap-3">
              <button
                onClick={() => setLogoutModal(false)}
                className="bg-textGrey text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
              >
                Close
              </button>
              <button
                onClick={handleLogout}
                className={`bg-buttonPrimary text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm`}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <section className="bg-white py-2 px-6 sticky top-0 z-10 flex items-center justify-between">
        <img src={logoImg} alt="logo" className="w-48" />
        <ul className="font-poppins font-bold text-blackSubDark text-[13px] 2xl:text-sm flex items-center gap-6 ">
          {navItems.map((item, index) => (
            <Link
              onClick={() => {
                setCurrTab(item.name);
                handleTab(item.name);
              }}
              className="cursor-pointer transition-all group"
              to={item.path}
              key={index}
            >
              <li className="relative">
                {item?.name}
                <div
                  className={`h-[2px] bg-skyBlue absolute left-0 -bottom-1 transition-all duration-300 ${
                    currTab === item.name ? "w-full" : "w-0 group-hover:w-full"
                  }`}
                ></div>
              </li>
            </Link>
          ))}
        </ul>
        <div className="flex items-center gap-2">
          <div className="text-right font-inter font-semibold">
            <p className="text-sm text-blackSub">{userName}</p>
            <p
              onClick={() => setLogoutModal(true)}
              className="text-xs text-skyBlue cursor-pointer"
            >
              Logout
            </p>
          </div>
          <div className="w-10 h-10 bg-gray-300 text-white flex items-center justify-center rounded-full font-bold">
            {firstLetter}
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
