import React from "react";
import chevronDown from "../assets/chevronDown.png";
import { FaChevronDown } from "react-icons/fa6";

const LocationPicker = () => {
  return (
    <h2 className="font-bold text-blackSubDark text-lg cursor-pointer">
      <span className="text-sm inline-block mr-2">
        <FaChevronDown />
      </span>
      Tamilnadu
    </h2>
  );
};

export default LocationPicker;
