import React, { useEffect } from "react";

const ConfirmationModal = ({
  type,
  setConfirmationModal,
  handleDelUser,
  handleUserStatusUpdate,
  confirmationModal,
  selectedUser,
}) => {
  useEffect(() => {
    if (confirmationModal) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [confirmationModal]);
  return (
    <div className="fixed inset-0 z-20  flex items-center justify-center bg-black bg-opacity-50 font-roboto">
      <div className="bg-white w-[30%] h-[30vh] rounded-xl flex flex-col gap-5 items-center justify-center">
        <h2 className="text-blackSubDark text-lg font-medium">
          Do you want to {type} this user?
        </h2>
        <div className="flex items-center justify-center gap-3">
          <button
            onClick={() => setConfirmationModal()}
            className="bg-textGrey text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
          >
            Close
          </button>
          <button
            onClick={
              confirmationModal === "delete"
                ? () => handleDelUser(selectedUser)
                : confirmationModal === "deactivate" ||
                  confirmationModal === "activate"
                ? () => handleUserStatusUpdate(selectedUser)
                : null
            }
            className={`bg-buttonPrimary text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm`}
          >
        
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
