import React from "react";
import { useLocation } from "react-router-dom";

const SurveyListCard = ({
  cardNumber,
  item,
  handleShowDetails,
  selectedWell,
  assignedDate,
  handleSelectSurvey,
  card,
}) => {
  const truncateLocation = (location) => {
    if (!location) return "";
    return location.length > 50 ? `${location.slice(0, 100)}...` : location;
  };

  const location = useLocation();

  const isSurveyReviewRoute = location.pathname.includes("survey-review");

  return (
    <div className="flex flex-col">
      <div
        className={`${
          selectedWell?.ex_well_id === item?.ex_well_id
            ? "bg-lightBlue"
            : "bg-white"
        } flex items-center rounded-lg py-2 justify-between px-2`}
      >
        <div className="w-[3%] text-center">{cardNumber}</div>
        <div className="border border-1 border-l h-[100px]"></div>
        <div className="w-[42%] pl-4">
          <p className="text-skyBlue font-roboto text-sm font-medium">
            <span className="text-textGrey font-roboto font-light text-xs my-2">
              Well type :
            </span>{" "}
            {item?.well_type}
          </p>
          <p className="text-blackSubDark font-roboto text-xs font-medium mt-2">
            Village : {item?.village}
          </p>
          <h4 className="text-blackSubDark font-poppins font-medium text-xs">
            well id : Well - {item?.ex_well_id} <span>|</span>{" "}
            <span
              className={`${
                item?.status === "Active"
                  ? "text-successGreen"
                  : "text-errorRed"
              } font-normal`}
            >
              {item?.status}
            </span>
          </h4>
          <p className="text-textGrey font-roboto text-xs my-2">
            <span className="text-black font-roboto font-medium text-sm my-2">
              location :
            </span>{" "}
            {truncateLocation(item?.location_detail)}
          </p>
          {isSurveyReviewRoute ? (
            <p className="text-textGrey font-roboto text-xs my-2">
              <span className="text-black font-roboto font-medium text-sm my-2">
                Water Level(mbgl) :
              </span>{" "}
              {item?.water_level}
            </p>
          ) : null}
        </div>
        <div className="border border-1 border-l h-[100px]"></div>
        <div className="w-[35%] px-1 text-[11px] text-blackSub font-roboto">
          <table className="table-fixed border-separate">
            <tbody>
              <tr>
                <td>Survey Assigned</td>
                <td className="px-2">:</td>
                <td className="font-extrabold">
                  {card === "missed" ? item?.assign_date : assignedDate}
                </td>
              </tr>
              <tr>
                <td>{isSurveyReviewRoute ? "Survey By" : "Assigned to"} </td>
                <td className="px-2">:</td>
                <td>{item?.name}</td>
              </tr>
              <tr>
                <td>Employee ID</td>
                <td className="px-2">:</td>
                <td>{card === "missed" ? item?.emp_id : item?.empid}</td>
              </tr>
              {card === "missed" ? null : (
                <tr>
                  <td>Survey Submmited</td>
                  <td className="px-2">:</td>
                  <td className="font-extrabold">
                    {item?.completed_date || "-"}
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  {card === "missed" ? "Survey status" : "Approve status"}
                </td>
                <td className="px-2">:</td>
                <td className="font-bold text-[#D28A29]">
                  {card === "missed"
                    ? item?.survey_status
                    : item?.survey_approve}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-[15%] px-2 flex flex-col justify-center gap-2">
          {card === "missed" ? (
            <button
              onClick={() => handleSelectSurvey(item)}
              className="bg-buttonPrimary text-white py-1 font-poppins font-medium rounded-[6px] text-[10px]"
            >
              Update Survey
            </button>
          ) : (
            <button
              onClick={() => handleShowDetails(item)}
              className="bg-buttonPrimary text-white py-1 font-poppins font-medium rounded-[6px] text-[10px]"
              aria-label={`View details for well ID ${item?.well_id}`}
            >
              View Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyListCard;
