import React from "react";

const WellHistoryListCard = ({
  item,
  handleShowDetails,
  selectedWellDetails,
  cardNumber,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={`${
          selectedWellDetails?.well_id === item?.well_id
            ? "bg-lightBlue"
            : "bg-white"
        } flex items-center rounded-lg py-4 gap-2`}
      >
        <div className="w-[3%] flex-col mx-1 text-center">
          <span className="font-bold text-xs">{cardNumber}</span>
        </div>
        <div className="w-[1px] bg-textGrey h-16"></div>
        <div className="w-[40%] pl-4">
          <h4 className="text-blackSubDark font-poppins font-semibold text-sm">
            {item?.village?.slice(0, 20)}
            {item?.village?.length > 20 && "..."} - {item?.well_type}
          </h4>
          <h4 className="text-blackSubDark font-poppins font-semibold text-sm">
            {item?.ex_well_id}
          </h4>

          <table className="text-xs font-roboto table-fixed border-separate">
            <tbody>
              <tr>
                <td>Depth Of Well(mbgl)</td>
                <td className="px-2">:</td>
                <td>{item?.survey_depth || "-"}</td>
              </tr>
              <tr>
                <td>Water Level(mbgl)</td>
                <td className="px-2">:</td>
                <td>{item?.water_level || "-"}</td>
              </tr>
              <tr>
                <td>Electrical Conductivity( ms/cm)</td>
                <td className="px-2">:</td>
                <td>{item?.electrical_conductivity || "-"}</td>
              </tr>
              <tr>
                <td>Temperature(c`)</td>
                <td className="px-2">:</td>
                <td>{item?.temperature || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-[30%] px-4 text-xs text-blackSub font-roboto">
          <table className="table-fixed border-spacing-y-2 border-separate">
            <tbody>
              <tr>
                <td>Survey Date</td>
                <td className="px-2">:</td>
                <td>{item?.createdat || "-"}</td>
              </tr>
              <tr>
                <td>Survey By</td>
                <td className="px-2">:</td>
                <td>{item?.name || "-"}</td>
              </tr>
              <tr>
                <td>Employee ID</td>
                <td className="px-2">:</td>
                <td className="font-bold">{item?.emp_id || "-"}</td>
              </tr>
              <tr>
                <td>Approved By</td>
                <td className="px-2">:</td>
                <td className="font-bold">{item?.reviewed_by || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-[15%] flex flex-col justify-center gap-2">
          <button
            onClick={() => handleShowDetails(item)}
            className="bg-buttonPrimary text-white py-1 font-poppins font-medium rounded-[6px] text-[10px]"
            aria-label={`View details for well ID ${item?.well_id}`}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default WellHistoryListCard;
