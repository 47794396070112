import axios from "axios";
import store from "../redux/store";
import { setAccessToken, setRefreshToken } from "../redux/authSlice";

const axiosInstance = axios.create({
  baseURL: "https://singhis.in",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { auth } = store.getState();
    const { accessToken } = auth;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { auth } = store.getState();
    const { refreshToken, user } = auth;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const response = await axios.post(
          "https://singhis.in/api/admin/refreshToken",
          { id: user?.userid, refreshToken }
        );

        const { token: newAccessToken, encryptedToken: newRefreshToken } =
          response.data;

        // Update the tokens in the Redux store
        store.dispatch(setAccessToken(newAccessToken));
        store.dispatch(setRefreshToken(newRefreshToken));

        // Update the Authorization header for the original request
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return axiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
