import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Back from "../components/Back";
import WellHistoryDetailsCard from "../components/WellHistoryDetailsCard";
import SurveyListCard from "../components/SurveyListCard";
import SelectedDistrictTable from "../components/SelectedDistricTable"; // Check for the correct file name
import excel from "../assets/Excel.png";
import { DistrictsContext } from "../context/DistrictsContext";
import axiosInstance from "../interceptor/axiosInstance";
import * as XLSX from "xlsx";
import { CircularProgress } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import Calendar from "react-calendar/dist/cjs/Calendar.js";
import { IoSearchOutline } from "react-icons/io5";
import UpdateSuccessModal from "../components/UpdateSuccessModal";

const SurveyReview = () => {
  const { district_name, emp_id } = useParams(); // Consolidate useParams
  const [survey, setsurvey] = useState([]);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [dateFilteredSurveys, setDateFilteredSurveys] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { districts } = useContext(DistrictsContext);
  const [showScreen, setShowScreen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const { updatedModal, setUpdatedModal, getDistricts } =
    useContext(DistrictsContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { filterDate, emp_name, date } = location.state;

  const surveyReview = async () => {
    if (!district_name || !emp_id) {
      console.error("District name or employee ID is missing.");
      return;
    }

    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `api/well/detail/get/${district_name}/${emp_id}/${filterDate?.startDate}/${filterDate?.endDate}`
      );
      const result = response?.data?.Data;
      setsurvey(result || []); // Ensure default value
      setFilteredSurveys(result || []);
      setDateFilteredSurveys(result || []);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setShowProgress(false);
      setShowScreen(true);
    }
  };

  useEffect(() => {
    surveyReview();
  }, [district_name, emp_id]);

  useEffect(() => {
    getDistricts();
  }, []);

  const handleShowDetails = (well) => {
    setSelectedWell(well);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedWell(null);
  };

  const selectedDistrict = districts?.find(
    (item) => item.district_name === district_name
  );

  const districtId = selectedDistrict?.district_id;

  const handleDownloadExcel = (wellType) => {
    // Format data based on well_type
    const filterData = survey.filter((well) =>
      well?.well_type.toLowerCase().includes(wellType?.toLowerCase())
    );

    const formattedData = filterData.map((well, i) => {
      if (well?.well_type.toLowerCase().includes("dug well")) {
        return {
          "No.": i + 1,
          District: selectedDistrict?.district_name || "-",
          "Well Type": well?.well_type || "-",
          "Well ID": well?.well_id || "-",
          Village: well?.village || "-",
          Location: well?.location_detail || "-",
          Latitude: well?.latitude || "-",
          Longitude: well?.longitude || "-",
          Status: well?.status || "-",
          "Survey By": well?.name || "-",
          "Submitted Date": well?.completed_date,
          "Survey Depth": well?.survey_depth || "-",
          "Survey Diameter": well?.survey_diameter || "-",
          "Survey Measuring Point": well?.survey_measuring_point || "-",
          "Water Level(mbgl)": well?.water_level || "-",
          "Electrical Conductivity": well?.electrical_conductivity || "-",
          "Temperature(c*)": well?.temperature || "-",
          PH: well?.ph_value || "-",
          "Base Parameter": well?.base_parameter || "-",
          TH: well?.th_value || "-",
          Ca: well?.ca_value || "-",
          Mg: well?.mg_value || "-",
          Na: well?.na_value || "-",
          K: well?.k_value || "-",
          CO3: well?.co3_value || "-",
          HCO3: well?.hco3_value || "-",
          CL: well?.cl_value || "-",
          SO4: well?.so4_value || "-",
          NO3: well?.no3_value || "-",
          "Heavy Metal": well?.heavy_metal || "-",
          F: well?.f_value || "-",
          U: well?.u_value || "-",
          Remark: well?.remarks || "-",
        };
      } else if (well?.well_type.toLowerCase().includes("piezometer")) {
        return {
          "No.": i + 1,
          District: selectedDistrict?.district_name,
          "Well Type": well.well_type || "-",
          "Well ID": well?.well_id || "-",
          Village: well?.village || "-",
          Location: well?.location_detail || "-",
          Latitude: well?.latitude || "-",
          Longitude: well?.longitude || "-",
          Status: well?.status || "-",
          "Survey By": well?.name || "-",
          "Submitted Date": well?.completed_date,
          "Key No": well?.key_no || "-",
          "Depth(m)": well?.depth || "-",
          "Diameter(m)": well?.diameter || "-",
          "Measuring Point(magl)": well?.measuring_point || "-",
          Vendor: well?.vendor || "-",
          "Protection Box": well?.protection_box_status || "-",
          "Type Of Lock": well?.type_of_lock || "-",
          "Water Level(mbgl)": well?.water_level || "-",
          "Electrical Conductivity": well?.electrical_conductivity || "-",
          "Temperature(c*)": well?.temperature || "-",
          PH: well?.ph_value || "-",
          "Base Parameter": well?.base_parameter || "-",
          TH: well?.th_value || "-",
          Ca: well?.ca_value || "-",
          Mg: well?.mg_value || "-",
          Na: well?.na_value || "-",
          K: well?.k_value || "-",
          CO3: well?.co3_value || "-",
          HCO3: well?.hco3_value || "-",
          CL: well?.cl_value || "-",
          SO4: well?.so4_value || "-",
          NO3: well?.no3_value || "-",
          "Heavy Metal": well?.heavy_metal || "-",
          F: well?.f_value || "-",
          U: well?.u_value || "-",
          Remark: well?.remarks || "-",
        };
      }
      return {};
    });
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${selectedDistrict?.district_name.toLowerCase()}-${wellType.toLowerCase()}-data.xlsx`
    );
  };

  const handleSearch = (searchItem) => {
    const filter = dateFilteredSurveys?.filter(
      (item) =>
        item?.village?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
        item?.well_type?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
        item?.ex_well_id?.toLowerCase()?.includes(searchItem?.toLowerCase())
    );
    setFilteredSurveys(filter);
  };

  const handleDateFilter = () => {
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day);
    };

    const filtered = survey?.filter((item) => {
      const surveyDate = parseDate(item?.completed_date);
      return surveyDate?.toDateString() === selectedDate?.toDateString();
    });

    setFilteredSurveys(filtered);
    setDateFilteredSurveys(filtered);
    setOpenCalendar(false);
  };

  return (
    <>
      <div className="w-[90%] mx-auto bg-softGrey">
        <div className="my-2 font-poppins">
          <h2 className="font-bold relative flex items-center capitalize text-blackSubDark text-lg">
            <span className="">
              <Back />
            </span>{" "}
            {`${emp_name} Completed Survey`}
          </h2>
        </div>
        {showProgress ? (
          <div className="w-[90%] mx-auto h-[90vh] flex items-center justify-center">
            <CircularProgress size={24} />
          </div>
        ) : null}
        {showScreen ? (
          <>
            <div className="my-4 flex items-center gap-6">
              <div className="w-[55%]">
                {selectedDistrict && (
                  <SelectedDistrictTable selectedDistrict={selectedDistrict} />
                )}
              </div>
              <div className="w-[45%] flex items-center gap-6">
                <button
                  onClick={() =>
                    navigate(
                      `/missed-survey/${emp_id}/${emp_name}/${districtId}`
                    )
                  }
                  className="w-[30%] py-3  text-sm font-medium rounded-md bg-[#009DE3]"
                >
                  Missed Survey
                </button>
                <div className="h-24 w-[70%] bg-[#3EB4E9] rounded-2xl font-poppins">
                  <h1 className="font-medium py-2  text-center text-sm">
                    Download Survey Well Data in Excel
                  </h1>
                  <div className="flex justify-center items-center gap-6 pt-2">
                    <div
                      onClick={() => handleDownloadExcel("Dug Well")}
                      className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                    >
                      <img className="w-5" src={excel} alt="" />
                      <span>Dug Well</span>
                    </div>
                    <div
                      onClick={() => handleDownloadExcel("Piezometer")}
                      className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                    >
                      <img className="w-5" src={excel} alt="" />
                      <span>Piezometer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 relative flex items-center gap-5">
              <div
                onClick={() => setOpenCalendar(!openCalendar)}
                className="bg-white font-poppins rounded-md shadow flex items-center gap-5 w-fit px-2 py-[9px] text-[13px] cursor-pointer"
              >
                {selectedDate
                  ? selectedDate?.toDateString()
                  : "Survey Submitted Date"}{" "}
                <span>
                  <IoMdArrowDropdown
                    className={`${
                      openCalendar ? "rotate-180" : ""
                    } transition duration-300 text-lg`}
                  />
                </span>
              </div>
              <div
                className={`absolute font-poppins ${
                  openCalendar ? "top-10 z-30 shadow-lg" : "-top-32 -z-30"
                }  left-0 bg-white rounded-md p-3 w-[240px] text-xs transition-all duration-500`}
              >
                <Calendar
                  value={selectedDate}
                  onChange={setSelectedDate}
                  className="flex flex-col items-center gap-2 text-center"
                  tileClassName={({ date }) => {
                    const isSelected =
                      selectedDate &&
                      date.toDateString() === selectedDate.toDateString();
                    const hasSurvey = survey.some(
                      (item) =>
                        new Date(item.date).toDateString() ===
                        date.toDateString()
                    );

                    return [
                      "p-2 rounded-full transition-all duration-200",
                      "hover:bg-lightBlue hover:text-buttonPrimary",
                      isSelected ? "bg-buttonPrimary text-white font-bold" : "",
                      hasSurvey ? "bg-green-100 text-green-600" : "",
                    ];
                  }}
                />
                <div className="flex items-center justify-end gap-3 mt-2">
                  <span
                    onClick={() => setOpenCalendar(false)}
                    className="cursor-pointer hover:underline"
                  >
                    Cancel
                  </span>
                  <button
                    disabled={!selectedDate}
                    onClick={() => {
                      setOpenCalendar(false);
                      setSelectedDate();
                      setFilteredSurveys(survey);
                    }}
                    className={`${
                      selectedDate
                        ? "cursor-pointer hover:underline"
                        : "text-lightGrey"
                    } transition-colors ml-2`}
                  >
                    Reset
                  </button>
                  <button
                    disabled={!selectedDate}
                    onClick={handleDateFilter}
                    className={`${
                      selectedDate
                        ? "cursor-pointer hover:bg-lightBlue text-buttonPrimary"
                        : "text-lightGrey"
                    } font-semibold p-2 rounded-full transition-colors`}
                  >
                    Ok
                  </button>
                </div>
              </div>

              <div className=" bg-white flex items-center w-[450px] h-[38px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
                <IoSearchOutline className="text-textGrey" />
                <input
                  type="text"
                  placeholder="Search by Well Id, Well Type, Village"
                  className="focus:outline-none w-full text-textGrey text-[14px]"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>

            {filteredSurveys && filteredSurveys?.length > 0 ? (
              <p className="font-poppins font-medium">
                {filteredSurveys?.length} completed surveys
              </p>
            ) : null}
            <div className="my-4 flex items-start gap-5">
              <div className="w-[56%] flex flex-col gap-2 h-[65vh] overflow-y-auto custom-scrollbar">
                {filteredSurveys && filteredSurveys?.length > 0 ? (
                  filteredSurveys?.map((item, i) => (
                    <SurveyListCard
                      cardNumber={i + 1}
                      assignedDate={date}
                      key={item?.well_id}
                      item={item}
                      selectedWell={selectedWell}
                      handleShowDetails={handleShowDetails}
                    />
                  ))
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <p className="font-medium">No completed surveys</p>
                  </div>
                )}
              </div>
              <div className="w-[44%]">
                {showDetailsModal && selectedWell && (
                  <WellHistoryDetailsCard
                    assignedDate={date}
                    approveBtn={true}
                    editBtn={true}
                    well={selectedWell}
                    closeDetailsModal={closeDetailsModal}
                  />
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      {updatedModal === "sample updated" ? (
        <UpdateSuccessModal message="Sample data inserted successfully!" />
      ) : null}
    </>
  );
};

export default SurveyReview;
