import React from "react";

const WellType_btn = ({ selectedType, setselectedType }) => {
  return (
    <div className="flex gap-4">
      <button
        className={`w-1/2 py-2 font-medium text-xs rounded-lg ${
          selectedType === "Dug well" ? "bg-buttonSecondary text-white" : "bg-white"
        }`}
        onClick={() => setselectedType("Dug well")}
      >
        Dug Well
      </button>
      <button
        className={`w-1/2 py-2 font-medium text-sm rounded-lg ${
          selectedType === "Piezometer" ? "bg-buttonSecondary text-white" : "bg-white"
        }`}
        onClick={() => setselectedType("Piezometer")}
      >
        Piezometer
      </button>
    </div>
  );
};

export default WellType_btn;
