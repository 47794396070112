import React, { useContext, useEffect } from "react";
import userPlaceholder from "../assets/user-placehoder.png";
import { EmpManagementContext } from "../context/EmpManagementContext";
const EmployeeCard = ({ user, setEmpModal }) => {
  const { empModal } = useContext(EmpManagementContext);
  useEffect(() => {
    if (empModal) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-roboto">
      <div className="bg-white p-6 rounded-[16px] w-1/4 h-[75vh] overflow-y-auto hide-scrollbar">
        <h3 className="text-2xl">Employee profile</h3>
        <div className="flex items-center gap-4 my-5">
          <img
            className="w-20 h-20 rounded-full object-fill"
            src={user?.user_pic ? user?.user_pic : userPlaceholder}
            alt="profile"
          />
          <div>
            <p
              className={`text-sm ${
                user?.user_status === "Active"
                  ? "text-successGreen"
                  : "text-errorRed"
              }`}
            >
              {user?.user_status}
            </p>
            <p className="capitalize text-xl">{user?.name}</p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-4 mb-4">
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-textGrey text-sm">Role</h5>
            <p className="bg-[#F6F6F6] rounded-md p-2 text-sm text-blackSubDark">
              {user?.role}
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-textGrey text-sm">Emp Id</h5>
            <p className="bg-[#F6F6F6] rounded-md p-2 text-sm text-blackSubDark">
              {user?.emp_id}
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-textGrey text-sm">Email</h5>
            <p className="bg-[#F6F6F6] rounded-md p-2 text-sm text-blackSubDark">
              {user?.email}
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h5 className="text-textGrey text-sm">Mobile No</h5>
            <p className="bg-[#F6F6F6] rounded-md p-2 text-sm text-blackSubDark">
              {user?.mobile_no}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-skyBlue text-white px-10 py-[6px] rounded-md text-[15px] font-medium "
            onClick={() => setEmpModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
