import React from "react";
import DistrictCard from "./DistrictCard";

const SelectedDistrictTable = ({ selectedDistrict }) => {
  return (
    <table className="w-[100%] pt-3 border-spacing-y-1 border-separate font-poppins h-24 rounded-2xl bg-white p-2">
      <thead>
        <tr className="text-sm text-center font-semibold">
          <td className="pl-2">District</td>
          <td>Total Wells</td>
          <td>Active Well</td>
          <td>Closed Wells</td>
        </tr>
      </thead>
      <tbody className="text-center">
        <DistrictCard item={selectedDistrict} />
      </tbody>
    </table>
  );
};

export default SelectedDistrictTable;
