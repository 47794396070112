import React from "react";
import download from "../assets/download.png";
import download_1 from "../assets/download_1.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const Download_Type = ({ data }) => {
  
  const handleDownloadPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const pageWidth = doc.internal.pageSize.width;
  
    doc.setFontSize(10);

    const heading = `District-${data[0]?.district_name} / Well Type-${data[0]?.well_type}`;

    const headingWidth = doc.getTextWidth(heading);

    // Center position calculation
    const headingX = (pageWidth - headingWidth) / 2;

    // Add centered text for District and Well Type
    doc.text(heading, headingX, 7);
    const tableData = data?.map((well, i) => {
      if (well?.well_type?.toLowerCase().includes("dug well")) {
        return [
          i + 1,
          well?.ex_well_id || "-",
          well?.village || "-",
          well?.location_detail || "-",
          well?.latitude || "-",
          well?.longitude || "-",
          well?.status || "-",
        ];
      } else if (well?.well_type.toLowerCase().includes("piezometer")) {
        return [
          i + 1,
          well?.ex_well_id || "-",
          well?.village || "-",
          well?.location_detail || "-",
          well?.latitude || "-",
          well?.longitude || "-",
          well?.status || "-",
          well?.key_no || "-",
          well?.depth || "-",
          well?.diameter || "-",
          well?.measuring_point || "-",
          well?.vendor || "-",
        ];
      }
      return [];
    });

    // Set up table columns based on the well type
    const columns = data[0]?.well_type.toLowerCase().includes("dug well")
      ? [
          "No.",
          "Well ID",
          "Village",
          "Location",
          "Latitude",
          "Longitude",
          "Status",
        ]
      : [
          "No.",
          "Well ID",
          "Village",
          "Location",
          "Latitude",
          "Longitude",
          "Status",
          "Key No",
          "Depth (m)",
          "Diameter (m)",
          "Measuring Point (magl)",
          "Vendor",
        ];

    // Add table to PDF
    autoTable(doc, {
      head: [columns],
      body: tableData,
      startY: 10,
      theme: "grid",
      headStyles: {
        textColor: [255, 255, 255],
        lineColor: [255, 255, 255], // White border color
        lineWidth: 0.1, // Thickness of the border
        fontSize: 5,
        halign: "center",
        valign: "middle",
        fillColor: [45, 128, 185],
      }, // Optional: customize header color
      margin: { top: 10 },
      pageBreak: "auto",
      tableWidth: "auto",
      bodyStyles: { fontSize: 5 }, // Reduce font size for table body
    });

    // Save the PDF
    doc.save(`${data[0]?.district_name}-${data[0]?.well_type}-well-data.pdf`);
  };

  const handleDownloadExcel = () => {
    // Format data based on well_type
    const formattedData = data.map((well, i) => {
      if (well?.well_type?.toLowerCase()?.includes("dug well")) {
        return {
          "No." : i+1,
          District: well?.district_name || "-",
          "Well Type": well?.well_type || "-",
          "Well ID": well?.well_id || "-",
          Village: well?.village || "-",
          Location: well?.location_detail || "-",
          Latitude: well?.latitude || "-",
          Longitude: well?.longitude || "-",
          Status: well?.status || "-",
        };
      } else if (well?.well_type?.toLowerCase()?.includes("piezometer")) {
        return {
          "No." : i+1,
          District: well?.district_name || "-",
          "Well Type": well?.well_type || "-",
          "Well ID": well?.well_id || "-",
          Village: well?.village || "-",
          Location: well?.location_detail || "-",
          Latitude: well?.latitude || "-",
          Longitude: well?.longitude || "-",
          Status: well?.status || "-",
          "Key No": well?.key_no || "-",
          "Depth(m)": well?.depth || "-",
          "Diameter(m)": well?.diameter || "-",
          "Measuring Point(magl)": well?.measuring_point || "-",
          Vendor: well?.vendor || "-",
        };
      }
      return {};
    });
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${data[0].district_name}-${data[0].well_type}-well-data.xlsx`
    );
  };

  return (
    <div className="flex items-center justify-center gap-10">
      <div
        onClick={handleDownloadPDF}
        className="cursor-pointer flex items-center justify-center gap-2"
      >
        <h1 className="text-[13px] font-bold text-buttonPrimary">
          Download as PDF
        </h1>
        <img className="w-3" src={download} alt="" />
      </div>
      <div
        onClick={handleDownloadExcel}
        className="cursor-pointer flex items-center justify-center gap-2"
      >
        <h1 className="text-[13px] font-bold text-dullRed">
          Download as Excel
        </h1>
        <img className="w-3 text-dullRed" src={download_1} alt="" />
      </div>
    </div>
  );
};

export default Download_Type;
