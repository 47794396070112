import React from "react";

const TextInput = ({
  label,
  name,
  value,
  onChange,
  isTextArea = false,
  placeholder,
  rows = 4,
  type = "text",
  ...props
}) => (
  <div className="">
    <label
      className="block text-buttonSecondary font-semibold text-xs my-2"
      htmlFor={name}
    >
      {label}
    </label>

    <input
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className=" appearance-none rounded w-full py-2 px-3 text-blackSub bg-softGrey leading-tight focus:outline-none focus:shadow-outline"
      {...props}
    />
  </div>
);

export default TextInput;
