import React, { useContext, useState } from "react";
import icon from "../assets/showWellDetailsIcon.png";
import { Link, useNavigate } from "react-router-dom";
import AssignBtn from "../buttons/AssignBtn";
import { useDispatch } from "react-redux";
import {
  setAllWells,
  setSelectedDist,
  toggleWell,
} from "../redux/assignmentSlice";
import axiosInstance from "../interceptor/axiosInstance";

const DistrictCard = ({ item, detailsIcon, assign }) => {
  const [showProgress, setShowProgress] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAssignAllClick = async () => {
    try {
      setShowProgress("Assign All");
      const response = await axiosInstance.get(
        `/api/district/well/detail/${item?.district_id}`
      );
      const wellDetails = response?.data?.Data;
      const unAssignedWells = wellDetails?.filter(
        (item) =>
          (item?.survey_status === "Completed" ||
            item?.survey_status === null) &&
          (item?.post_approval === "Approved" || item?.post_approval === null)
      );

      if (unAssignedWells) {
        dispatch(setSelectedDist(item?.district_name));
        dispatch(setAllWells(unAssignedWells));
        dispatch(toggleWell(unAssignedWells?.map((item) => item?.well_id)));
        navigate(`/assign-work/${item?.district_name}`);
      }
      setShowProgress("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleCustomAssignClick = async () => {
    try {
      setShowProgress("Custom Assign");
      const response = await axiosInstance.get(
        `/api/district/well/detail/${item?.district_id}`
      );
      const wellDetails = response?.data?.Data;
      const unAssignedWells = wellDetails?.filter(
        (item) =>
          (item?.survey_status === "Completed" ||
            item?.survey_status === null) &&
          (item?.post_approval === "Approved" || item?.post_approval === null)
      );

      if (unAssignedWells) {
        navigate(`/assign-work/${item?.district_name}`);
        dispatch(setSelectedDist(item?.district_name));
        dispatch(setAllWells(unAssignedWells));
      }
      setShowProgress("");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <tr className="bg-white mb-4 border-[1px] border-[#EAECF0] shadow-sm shadow-white text-[13px]">
      <td className="py-3 pl-2">{item?.district_name}</td>
      <td className="py-3">{item?.TotalWell}</td>
      <td className="py-3">{item?.Active}</td>
      <td className="py-3">{item?.Closed}</td>
      {detailsIcon === "true" ? (
        <Link to={`/selected-district/${item?.district_id}`}>
          <td className="py-3">
            <button className="bg-buttonPrimary p-2 rounded-md text-white text-[10px]">
              View District
            </button>
          </td>
        </Link>
      ) : null}
      {assign === "true" ? (
        <>
          <td className="py-3">
            <div onClick={handleAssignAllClick}>
              <AssignBtn showProgress={showProgress} label="Assign All" />
            </div>
          </td>
          <td className="py-3">
            <div onClick={handleCustomAssignClick}>
              <AssignBtn showProgress={showProgress} label="Custom Assign" />
            </div>
          </td>
        </>
      ) : null}
    </tr>
  );
};

export default DistrictCard;
