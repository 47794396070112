import React, { useContext, useEffect, useState } from "react";
import LocationPicker from "../components/LocationPicker";
import EmpListToAssign from "../components/EmpListToAssign";
import { EmpAssignmentContext } from "../context/EmpAssignmentContext";
import axiosInstance from "../interceptor/axiosInstance";
import AssigmentCard from "../components/AssigmentCard";
import { CircularProgress } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";

const EmployeeAssignments = () => {
  const [searchTerms, setsearchTerms] = useState("");
  const { showAssignmentsForEmp } = useContext(EmpAssignmentContext);
  const [assignedDistricts, setAssignedDistricts] = useState();
  const [showProgress, setShowProgress] = useState();
  const getEmpAssignment = async (emp) => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/employee/work/assigned/status/${emp}`
      );

      const data = response.data?.Data;
      setAssignedDistricts(data);

      setShowProgress(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="w-[90%] mx-auto">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold text-blackSubDark text-lg">
              Survey Assignments
            </h2>
            <LocationPicker />
          </div>
          <h3 className="text-blackSub text-[13px]">
            Assign and manage survey tasks across employees.
          </h3>
          <div className="my-6 bg-white flex items-center w-[450px] h-[40px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
            <IoSearchOutline className="text-textGrey" />{" "}
            <input
              type="text"
              placeholder="Search by Employee Name or Id"
              className="focus:outline-none w-[290px] px-2 rounded-[4px] mr-2 text-textGrey text-[14px] font-roboto"
              onChange={(e) => setsearchTerms(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex bg-white rounded-2xl h-[75vh] items-start mb-6 gap-4 w-[90%] mx-auto px-2">
        <div className="w-1/2 overflow-y-auto h-full custom-scrollbar">
          <EmpListToAssign
            searchTerms={searchTerms}
            getEmpAssignment={getEmpAssignment}
          />
        </div>

        <div
          className={`flex ${
            assignedDistricts?.length > 0
              ? "items-start justify-start"
              : "items-center justify-center"
          } h-full  overflow-y-auto custom-scrollbar w-1/2 rounded-2xl gap-4 flex-wrap py-4`}
        >
          {assignedDistricts ? (
            <>
              {showProgress === true ? (
                <div className="w-full h-full flex items-center justify-center">
                  <CircularProgress sx={{ margin: "24px" }} size={24} />
                </div>
              ) : assignedDistricts?.length > 0 ? (
                assignedDistricts?.map((item, i) => (
                  <AssigmentCard
                    key={i}
                    assignment={item}
                    emp_id={showAssignmentsForEmp}
                  />
                ))
              ) : (
                <h3 className="font-semibold text-[17px] text-buttonSecondary">
                  No Assigned Tasks
                </h3>
              )}
            </>
          ) : (
            <p className="font-semibold text-[17px] text-buttonSecondary">
              Select a Employee to View their Assigned task
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeAssignments;
