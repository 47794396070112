import React from "react";

const SurveyDetailsCard = ({ well }) => {
  

  const surveyData = [
    {
      label: "Depth Of Well(mbgl)",
      value: well?.depth || well?.survey_depth || "-",
    },
    {
      label: "Diameter(m)",
      value: well?.diameter || well?.survey_diameter || "-",
    },
    {
      label: "Measuring Point(magl)",
      value: well?.measuring_point || "-",
    },
    {
      label: "Water Level(mbgl)",
      value: well?.water_level || "-",
    },
    {
      label: "Electrical Conductivity",
      value: well?.electrical_conductivity || "-",
    },
    {
      label: "Temperature(c*)",
      value: well?.temperature || "-",
    },
    {
      label: "PH",
      value: well?.ph_value || "-",
    },
    {
      label: "Ground Water Level Sample :",
      value: well?.depth || "-",
    },
    {
      label: "Base Parameter",
      value: well?.base_parameter || "-",
      isFlex: true,
    },
    {
      label: "Heavy Metal",
      value: well?.heavy_metal || "-",
      isFlex: true,
    },
    {
      label: "Remark",
      value: well?.remarks || "-",
    },
  ];

  const surveyList = [
    {
      label: "Well Type",
      value: well?.well_type || "-",
    },
    {
      label: "Village",
      value: well?.village || "-",
    },
    {
      label: "Location Details",
      value: well?.location_detail || "-",
    },
    {
      label: "latitude",
      value: well?.latitude || "-",
    },
    {
      label: "longitude",
      value: well?.longitude || "-",
    },
    {
      label: "Measuring Point(magl)",
      value: well?.measuring_point || "-",
    },
    {
      label: "Diameter",
      value: well?.diameter || well?.survey_diameter || "-",
    },
    {
      label: "Vendor",
      value: well?.name || "-",
    },
  ];

  return (
    <div className="rounded-lg font-roboto w-[100%] bg-white pb-5 h-[80vh] overflow-y-auto custom-scrollbar">
      <div className="flex items-start bg-white rounded-lg gap-3 px-6 pb-4 justify-between">
        <div className="w-[50%]">
          <h1 className="font-poppins font-bold pt-4  text-buttonSecondary text-[17px] mb-2">
            Survey details
          </h1>

          <table className="table-fixed text-xs border-spacing-y-2 border-separate text-blackSub">
            <tbody>
              <tr>
                <td>Survey Assigned</td>
                <td className="px-1">:</td>
                <td>{well?.createdat}</td>
              </tr>
              <tr>
                <td>Survey By</td>
                <td className="px-1">:</td>
                <td>{well?.name}</td>
              </tr>
              <tr>
                <td>Employee ID</td>
                <td className="px-1">:</td>
                <td>{well?.emp_id}</td>
              </tr>
              <tr>
                <td>Survey Submmited</td>
                <td className="px-1">:</td>
                <td>{well?.completed_date}</td>
              </tr>
            </tbody>
          </table>

          {surveyData.map((item, index) => {
            return (
              <div key={index} className="mt-4">
                <h1 className="text-textGrey text-xs my-1">{item.label}</h1>
                <p className="font-medium text-blackSubDark">{item.value}</p>
              </div>
            );
          })}

          <h1 className="text-textGrey text-xs my-3">Photos</h1>
          <div className="flex flex-wrap gap-1 mt-2">
            {well?.well_image && well?.well_image.length > 0 ? (
              well?.well_image.map((image, index) => (
                <img
                  key={index}
                  className="w-24 h-auto cursor-pointer rounded-lg"
                  src={image || "fallback-image-url.jpg"}
                  alt={`well-image-${index}`}
                  onError={(e) => (e.target.src = "fallback-image-url.jpg")}
                />
              ))
            ) : (
              <p>No images available</p>
            )}
          </div>
        </div>

        <div className="border border-l border-gray-200 border-1 w-0 h-[820px] mt-24 ">
          {" "}
        </div>
        <div className="w-[50%]">
          <h1 className="font-poppins font-bold pt-4 text-buttonSecondary text-[17px] mb-2">
            Well id : well-{well?.ex_well_id || "-"}
          </h1>
          <h4 className="font-poppins text-[15px] font-bold pt-2 text-buttonSecondary">
            Well status :{" "}
            <span
              className={`${
                well?.status === "Active"
                  ? "text-successGreen "
                  : "text-errorRed"
              } font-bold text-sm`}
            >
              {well?.status || "-"}
            </span>{" "}
          </h4>
          <h4 className="font-poppins font-bold text-[15px] text-buttonSecondary">
            Key No : {well?.KeyNo || "-"}
          </h4>
          <p className="border border-x mt-4"></p>

          {surveyList.map((item) => {
            return (
              <div>
                <h1 className="text-textGrey text-xs mt-4">{item.label}</h1>
                <p className="text-[15px] text-blackSubDark">{item.value}</p>
              </div>
            );
          })}
          <h1 className="text-textGrey font-normal text-xs mt-4 mb-2">Map</h1>
          <iframe
            name="gMap"
            style={{ width: "100%", height: "250px" }}
            className="rounded-lg"
            src={`https://www.google.com/maps/embed/v1/place?q=${well?.latitude},${well?.longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}
          ></iframe>
        </div>
      </div>
      <button className="px-8 py-2 rounded-lg text-white ml-80 text-center bg-[#006CE7]">
        Approve
      </button>
    </div>
  );
};

export default SurveyDetailsCard;
