import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const TableComponent = ({ reportData, filterDate, showProgress }) => {
  const navigate = useNavigate();

  const heading = [
    "#",
    "District Name",
    "Assigned Employee",
    "Survey Status",
    "Surveys Assigned",
    "Surveys Pending",
    "Surveys Completed",
    "Assign Date",
  ];

  const handleRowClick = (
    district_name,
    district_id,
    emp_id,
    emp_name,
    date
  ) => {
    
    navigate(`/survey-review/${district_name}/${emp_id}`, {
      state: { filterDate, emp_name, date },
    });
  };

  return (
    <>
      <div className=" bg-skyBlue p-1 text-white text-center mt-4 rounded-t-lg">
        <h2 className="font-bold text-xl">{`${filterDate?.startMonth}-${filterDate?.endMonth}(${filterDate?.year})`}</h2>
      </div>
      <div className="overflow-auto h-[70vh] custom-scrollbar w-[100%] mx-auto mb-4  bg-[#FFFFFF] shadow-sm rounded-b-lg font-inter">
        <table className="min-w-full h-full font-poppins">
          <thead className="sticky bg-white w-full top-0">
            <tr>
              {heading?.map((head, index) => (
                <th
                  key={index}
                  className="text-[#000000] top-0 text-[13px] p-3 text-center"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          {showProgress ? (
            <tbody>
              <tr className="my-auto">
                <td colSpan={heading?.length} className="text-center">
                  <CircularProgress size={24} />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {reportData?.map((item, index) => (
                <tr
                  key={index}
                  className="cursor-pointer"
                  onClick={() =>
                    handleRowClick(
                      item?.district_name,
                      item?.district_id,
                      item?.emp_id,
                      item?.name,
                      item?.date
                    )
                  }
                >
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {index + 1}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.district_name}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.name}
                  </td>
                  <td
                    className={`${
                      item?.status === "Pending"
                        ? "text-red-500"
                        : item?.status === "In Progress"
                        ? "text-yellow-500"
                        : item?.status === "Completed"
                        ? "text-activeGreen"
                        : ""
                    } border-b font-medium text-[#404040] text-[13px] text-center border-gray-200 p-3`}
                  >
                    {item?.status}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.survey_assigned}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.survey_pending}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.survey_completed}
                  </td>
                  <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                    {item?.assign_date}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default TableComponent;
