import { createContext, useEffect, useState } from "react";
import axiosInstance from "../interceptor/axiosInstance";
const DistrictsContext = createContext();

const DistrictsProvider = ({ children }) => {
  const [currTab, setCurrTab] = useState();
  const [districts, setDistricts] = useState();
  const [editFormModal, setEditFormModal] = useState();
  const [updatedModal, setUpdatedModal] = useState();

  const getDistricts = async () => {
    try {
      const response = await axiosInstance.get(`/api/district/get/data`);
      const districts = response?.data?.Data;
      setDistricts(districts);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDistricts();
  }, []);

  return (
    <DistrictsContext.Provider
      value={{
        districts,
        setDistricts,
        currTab,
        setCurrTab,
        editFormModal,
        setEditFormModal,
        updatedModal,
        setUpdatedModal,
        getDistricts,
      }}
    >
      {children}
    </DistrictsContext.Provider>
  );
};

export { DistrictsProvider, DistrictsContext };
