import React from "react";

const DashCard = ({ title, color, item, description }) => {
  const colorClasses = {
    white: "bg-white",
    lightBlue: "bg-lightBlue",
    lightYellow: "bg-lightYellow",
    lightGreen: "bg-lightGreen",
  };

  return (
    <div
      className={`${colorClasses[color]} rounded-lg p-4 flex flex-col justify-center gap-2 items-center w-[240px] h-[90px]`}
    >
      <p className="text-blackSub font-inter font-semibold text-sm">{title}</p>
      <h3 className="font-poppins text-blackSubDark font-bold text-3xl capitalize">
        {item}
      </h3>
      <p className="text-blackSub font-roboto text-xs">{description}</p>
    </div>
  );
};

export default DashCard;
