import React, { useContext, useEffect, useState } from "react";
import Back from "../components/Back";
import LocationPicker from "../components/LocationPicker";
import { useNavigate, useParams } from "react-router-dom";
import { DistrictsContext } from "../context/DistrictsContext";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAssignment,
  clearWells,
  setSelectedDist,
} from "../redux/assignmentSlice";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";
import AssgWellListCard from "../components/AssgWellListCard";
import WellDetailsCard from "../components/WellDetailsCard";
import UpdateSuccessModal from "../components/UpdateSuccessModal";
import { IoSearchOutline } from "react-icons/io5";
import { socket } from "../socket";
import SelectedDistrictTable from "../components/SelectedDistricTable";

const SelectDistrictAndWell = () => {
  const [notSelectedMsg, setNotSelectedMsg] = useState();
  const [showProgress, setShowProgress] = useState();
  const [showWellDetail, setShowWellDetail] = useState();
  const { districts, updatedModal, setUpdatedModal, getDistricts } =
    useContext(DistrictsContext);
  const { district } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedEmp, selectedDist, selectedWell, wellDetails } = useSelector(
    (state) => state.assignment
  );
  const [filteredData, setFilteredData] = useState(wellDetails);

  useEffect(() => {
    getDistricts();
  }, []);

  const selectedDistrict = districts?.find(
    (item) => item?.district_name === district
  );

  const handleBack = () => {
    dispatch(clearWells());
    dispatch(setSelectedDist(null));
  };

  const handleSearch = (e) => {
    setShowWellDetail();
    const searchTerm = e.target.value;
    const result = wellDetails?.filter(
      (item) =>
        item?.well_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ex_well_id.toString().includes(searchTerm.toString()) ||
        item?.village.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(result);
  };

  const handleAssignSubmit = async () => {
    if (selectedWell.length > 0) {
      try {
        setShowProgress("assign-confirmation");
        const response = await axiosInstance.post(
          "/api/work/customBased/assign",
          {
            emp_id: selectedEmp?.emp_id,
            wellId: selectedWell,
            reassign: false,
            district_name: selectedDistrict?.district_name,
          }
        );

        socket.emit(
          "notification_send",
          selectedEmp?.emp_id,
          response?.data?.message
        );

        dispatch(clearAssignment());
      } catch (err) {
        console.error(err);
      } finally {
        setShowProgress("");
        setUpdatedModal(true);
      }
    } else {
      setNotSelectedMsg("Please select atleast one well");
    }
  };

  const handleNavigate = () => {
    navigate("/employee-assignments");
  };

  return (
    <>
      <div className="w-[90%] mx-auto ">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              <span onClick={handleBack} className="">
                <Back />
              </span>{" "}
              {`Employee Assignment - ${selectedEmp?.name} (${selectedEmp?.emp_id})`}
            </h2>

            <LocationPicker />
          </div>
        </div>

        <div className="w-[65%] flex items-center gap-6 relative">
          <SelectedDistrictTable selectedDistrict={selectedDistrict} />

          <button
            disabled={selectedWell?.length <= 0}
            onClick={handleAssignSubmit}
            className={`${
              selectedWell?.length <= 0
                ? "bg-lightGrey text-black cursor-not-allowed"
                : "bg-skyBlue text-white"
            }  ${
              showProgress === "assign-confirmation" ? "px-[45px]" : "px-8"
            } py-[6px] font-poppins rounded-[5px] text-xs mb-[2px] transition-colors`}
          >
            {showProgress === "assign-confirmation" ? (
              <CircularProgress color="default" size={11} />
            ) : (
              "Assign"
            )}
          </button>
        </div>
        <div className="my-4 bg-white flex items-center w-[500px] h-[40px] border px-8 border-gray-300 rounded-lg  gap-4 font-roboto shadow-md">
          <IoSearchOutline className="text-textGrey" />
          <input
            onChange={(e) => handleSearch(e)}
            type="text"
            placeholder="Search by Well ID, Well Type, Village"
            className="focus:outline-none w-full  text-lightGrey text-[14px]"
          />
        </div>

        <div className="flex items-start justify-between gap-6">
          <div className="w-[55%] h-[83vh] overflow-y-auto custom-scrollbar flex flex-col gap-2 mb-4">
            {filteredData.length > 0 ? (
              filteredData?.map((item, i) => (
                <AssgWellListCard
                  showWellDetail={showWellDetail}
                  setShowWellDetail={setShowWellDetail}
                  key={i}
                  cardNumber={i + 1}
                  item={item}
                  buttons="false"
                  checkbox="true"
                />
              ))
            ) : (
              <p className="font-poppins text-blackSubDark text-[14px]">
                No more wells to assign.
              </p>
            )}
          </div>
          <div
            className={`transition-opacity w-[45%] rounded-lg bg-white p-4 mb-4`}
          >
            {!showWellDetail ? (
              <div className="p-4">
                <p className="font-semibold text-center py-24 text-lg">
                  Select a well to view details.
                </p>
              </div>
            ) : (
              <WellDetailsCard
                edit={false}
                details={showWellDetail}
                type="details"
              />
            )}
          </div>
        </div>
      </div>
      {updatedModal === true ? (
        <UpdateSuccessModal
          message="Work assingned successfully"
          handleNavigate={handleNavigate}
        />
      ) : null}
    </>
  );
};

export default SelectDistrictAndWell;
