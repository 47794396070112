import React, { useContext, useEffect } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import UploadDugWellSheet from "./UploadDugWellSheet";
import UploadPiezometerSheet from "./UploadPiezometerSheet";
import { DistrictsContext } from "../context/DistrictsContext";
import * as XLSX from "xlsx";
import UpdateSuccessModal from "./UpdateSuccessModal";
import { MdDownloading } from "react-icons/md";

const UploadModal = ({ type, setOpenModal, selectedDistrict }) => {
  const { updatedModal, setUpdatedModal } = useContext(DistrictsContext);

  useEffect(() => {
    if (type) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleCloseModal = () => {
    setOpenModal("");
    setUpdatedModal(false);
  };

  const handleDownloadSampleExcel = (type) => {
    // Define sample headings for both well types
    const sampleData = [
      type === "dug-well" && {
        District: "",
        "Well Type": "",
        "Ex Well ID": "",
        Village: "",
        "Location Detail": "",
        Latitude: "",
        Longitude: "",
        "Well Status": "",
      },
      type === "piezometer" && {
        District: "",
        "Well Type": "",
        "Ex Well ID": "",
        Village: "",
        "Location Detail": "",
        Latitude: "",
        Longitude: "",
        "Well Status": "",
        "Key No": "",
        Depth: "",
        Diameter: "",
        MP: "",
        Vendor: "",
      },
    ];

    // Choose one row for Dug Wells and another for Piezometers
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample Well Data");

    // Save the workbook with a meaningful file name
    XLSX.writeFile(workbook, `${type}-sample-sheet.xlsx`);
  };
  return (
    <>
      {updatedModal === true && (
        <UpdateSuccessModal message="Data inserted successfully!" />
      )}
      <div className="h-screen font-poppins fixed inset-0 z-30 w-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white  w-[50%] h-[75vh] flex flex-col gap-4 shadow-xl relative my-8 px-4 mx-auto rounded-xl">
          <div className="mt-7">
            <h1 className="text-center font-semibold text-lg">{`${type} Data`}</h1>
            <div className="p-2 rounded-full hover:bg-black hover:bg-opacity-20 absolute right-5 top-5 transition">
              <IoMdCloseCircleOutline
                onClick={handleCloseModal}
                className="cursor-pointer"
                size={22}
              />
            </div>
          </div>

          <div className="list-disc mt-4 text-md mx-auto md:w-[70%] lg:w-[60%]">
            <li>Upload only {selectedDistrict} Well data.</li>
            <li>Upload only (.xlsx) format</li>
            <li>Sheet table headings:</li>
            {type === "Piezometer" ? (
              <>
                <p className="ml-6 text-errorRed">
                  Note : Headings should not contain spaces around.
                </p>
                <p className="ml-6">
                  (District, Ex Well Id, Village, Location Detail, Well Type,
                  Well Status, Latitude, Longitude, Depth, Diameter, MP, Key No,
                  Vendor)
                </p>
                <li
                  onClick={() => handleDownloadSampleExcel("piezometer")}
                  className="my-2 text-errorRed hover:underline cursor-pointer font-medium inline-flex items-center list-disc gap-1"
                >
                  <span className="text-xl">
                    <MdDownloading />
                  </span>
                  <span>Download a sample piezometer sheet</span>
                </li>
              </>
            ) : (
              <>
              <p className="ml-6 text-errorRed">
                  Note : Headings should not contain spaces around.
                </p>
                <p className="ml-[22px]">
                  (District, Ex Well Id, Village, Location Detail, Well Type,
                  Well Status, Latitude, Longitude)
                </p>
                <li
                  onClick={() => handleDownloadSampleExcel("dug-well")}
                  className="my-2 text-errorRed hover:underline cursor-pointer font-medium inline-flex items-center list-disc gap-1"
                >
                  <span className="text-xl">
                    <MdDownloading />
                  </span>
                  <span>Download a sample dug well sheet</span>
                </li>
              </>
            )}
          </div>
          {type === "Dug Well" && (
            <UploadDugWellSheet setOpenModal={setOpenModal} />
          )}
          {type === "Piezometer" && (
            <UploadPiezometerSheet setOpenModal={setOpenModal} />
          )}
        </div>
      </div>
    </>
  );
};

export default UploadModal;
