import React, { useContext, useEffect, useState } from "react";
import DashCard from "../components/DashCard";
import Searchbar from "../components/Searchbar";
import DistrictCard from "../components/DistrictCard";
import { DistrictsContext } from "../context/DistrictsContext";
import LocationPicker from "../components/LocationPicker";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";

const DistrictManagement = () => {
  const { districts, getDistricts } = useContext(DistrictsContext);
  const [searchTerms, setsearchTerms] = useState("");
  const [showProgress, setShowProgress] = useState();
  const [apiResult, setApiResult] = useState({});

  const filterData = districts?.filter((item) =>
    item.district_name?.toLowerCase().includes(searchTerms.toLowerCase())
  );

  const fetchApiData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get("/api/well/over/allWell/counts");
      const outputData = response?.data?.Data;
      setApiResult(outputData);
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    fetchApiData();
    getDistricts();
  }, []);

  return (
    <>
      <div className="w-[90%] mx-auto">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold text-blackSubDark text-lg">
              District Management Overview
            </h2>
            <LocationPicker />
          </div>
          <h3 className="text-blackSub text-[13px]">
            Manage and monitor district data
          </h3>
        </div>
        <div className="my-2 flex items-center justify-start gap-7">
          <DashCard
            title="Total Districts"
            color="white"
            item={apiResult?.total_district_count}
          />
          <DashCard
            title="Overall district Well"
            color="lightBlue"
            item={apiResult?.total_well_count}
          />
          <DashCard
            title="Overall district Active Well"
            color="lightYellow"
            item={apiResult?.total_active_well_count}
          />
          <DashCard
            title="Overall district Closed Well"
            color="lightGreen"
            item={apiResult?.total_closed_well_count}
          />
        </div>
        <Searchbar setsearchTerms={setsearchTerms} />
        {showProgress ? (
          <div className="w-[90%] mx-auto h-[30vh] flex items-center justify-center">
            <CircularProgress size={24} />
          </div>
        ) : (
          <div className="w-[80%] mt-4 font-poppins max-h-[65vh] overflow-y-auto custom-scrollbar">
            <table className="border-spacing-y-1 w-full border-separate">
              <thead className="sticky top-0 bg-softGrey w-full">
                <tr className="mb-4 text-sm">
                  <td className="pl-2 text-[15px] font-semibold">District</td>
                  <td className="text-[15px] font-semibold">Total Wells</td>
                  <td className="text-[15px] font-semibold">Active Well</td>
                  <td className="text-[15px] font-semibold">Closed Wells</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {filterData?.map((item, index) => (
                  <DistrictCard key={index} item={item} detailsIcon="true" />
                ))}
              </tbody>
            </table>
            {filterData?.length <= 0 && (
              <p className="my-7">No districts found.</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DistrictManagement;
