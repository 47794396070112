import React, { useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";

const Searchbar = ({ setsearchTerms }) => {
  const searchRef = useRef();
  const handleSearchClick = () => {
    searchRef.current.focus();
  };
  return (
    <div className="my-6 bg-white flex items-center w-[450px] h-[40px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
      <IoSearchOutline onClick={handleSearchClick} className="text-textGrey cursor-pointer" />
      <input
        ref={searchRef}
        type="text"
        placeholder="Search by district name"
        className="focus:outline-none w-full text-textGrey text-[14px]"
        onChange={(e) => setsearchTerms(e.target.value)}
      />
     
    </div>
  );
};

export default Searchbar;
